.container {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.image-card {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: 0.3s;
  align-items: center;
  display: flex;
}
.image-card:hover {
  cursor: pointer;
  transition: all;
}
.image {
  width: 100%;
}
.tags {
  text-align: center;
  padding: 20px 0px;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: -30px;
}
.tag {
  outline: none;
  border: none;
  color: #eee;
  margin: 0px 10px;
  background-color: transparent;
  cursor: pointer;
}
.avtive {
  font-weight: bold;
  border-bottom: 1px solid #eee;
}